import React, { useEffect, useState } from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPaymentStatus } from '../../../../services/payment';

const PaymentDue = () => {
  
  const user = useSelector(({ user }) => user);
  const [isVisible, setIsVisible] = useState(true);
  const [paymentStatus , setPaymentStatus] = useState({});
  
  useEffect(()=>{
    if(user.user.des === 101){
      getPaymentStatus().then(({data}) => setPaymentStatus(data));
    }
  },[user])

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {paymentStatus.status === 'pending' && isVisible  && (
        <div className='side-modal'>
          <div className='side-modal-inner'>
            <h4 className='side-modal-text'>
              Your Payment is pending, Please make a payment to continue using our services
              <Link to={'/admin-panel/users/billing'}  style={{ color: "yellow" }}>PAY NOW</Link>
            </h4>
            <IoIosCloseCircleOutline onClick={handleClose} style={{
              cursor: "pointer",
              color: "white"
            }} size={24} />
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentDue