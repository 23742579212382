import { useEffect, useState, useMemo } from "react";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectUserStyles } from "../../../../public/stylesheets/selectStyles";
import { viewUsersForUtilities, viewcityWiseDSCreports, viewhqWiseDSCreports, viewlocalAreaWiseDSCreports } from "../../../../../services/utilities";
import ListExport from "../../../../../components/ListExport";

const HQ_City_LocalArea = () => {

  const areaTypeoptions = [
    { value: 1, label: "HQ" },
    { value: 2, label: "City" },
    { value: 3, label: "Local Area" }
  ]; 
  
  const dispatch = useDispatch(); 

  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [areaType, setAreaType] = useState(areaTypeoptions[0]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);


const columns = useMemo(() =>{
    if(areaType?.label === "HQ" && !loading){
        return [
            // {
            // Header: "Sr. No",
            // accessor: "sr",
            // disableFilters: true,
            // minWidth: 40,
            // maxWidth: 40,
            // showTotal: true,
            // placeholderTotal: "Total",
            // },
            {
            Header:`${areaType?.label}`,
            accessor:"city",
            disableFilters:true,
            minWidth:60,
            maxWidth: 70,
            },
            {
            Header: "Total Doctors",
            accessor: "doctors",
            disableFilters: true,
            minWidth: 40,
            maxWidth: 40,
            showTotal: true,
            totalAccessor: "doctors",
            },
            {
            Header: "Total Stockists",
            accessor: "stockists",
            disableFilters: true,
            minWidth: 40,
            maxWidth: 40,
            showTotal: true,
            totalAccessor: "stockists",
            },
            {
            Header: "Total Chemists",
            accessor: "chemists",
            disableFilters: true,
            minWidth: 40,
            maxWidth: 40,
            showTotal: true,
            totalAccessor: "chemists",
            },
        ];
    }
    else if(!loading) {
        return [
            // {
            // Header: "Sr. No",
            // accessor: "sr",
            // disableFilters: true,
            // minWidth: 40,
            // maxWidth: 40,
            // showTotal: true,
            // placeholderTotal:"Total"
            // },
            {
            Header:`${areaType?.label}`,
            accessor:"city",
            disableFilters:true,
            minWidth:60,
            maxWidth: 70,
            },
            {
            Header:"Headquarter",
            accessor:"headquarter",
            disableFilters:true,
            minWidth:60,
            maxWidth: 70,
            },
            {
            Header: "Total Doctors",
            accessor: "doctors",
            disableFilters: true,
            minWidth: 40,
            maxWidth: 40,
            showTotal: true,
            totalAccessor: "doctors"
            },
            {
            Header: "Total Stockists",
            accessor: "stockists",
            disableFilters: true,
            minWidth: 40,
            maxWidth: 40,
            showTotal: true,
            totalAccessor: "stockists"
            },
            {
            Header: "Total Chemists",
            accessor: "chemists",
            disableFilters: true,
            minWidth: 40,
            maxWidth: 40,
            showTotal: true,
            totalAccessor: "chemists"
            },
        ];
    }
},[areaType,loading]);


  useEffect(() => {
    setLoading(true); // Set loading to true before data fetching starts
  
    const fetchData = async () => {
      try {
        let data;
        if (areaType?.label === "HQ") {
          data = await viewhqWiseDSCreports({ userId: selectedUser?.value });
        } else if (areaType?.label === "City") {
          data = await viewcityWiseDSCreports({ userId: selectedUser?.value });
        } else {
          data = await viewlocalAreaWiseDSCreports({ userId: selectedUser?.value });
        }
        setData(data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data fetching completes
      }
    };
  
    fetchData();
  
  }, [selectedUser, areaType]);
  


  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const tableData2 = useMemo(() => serialise(data),[data]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate("/utilities")}
          />
          <h2 className="web-app__heading">Doctors / Stockists / Chemists List</h2>
        </section>
        <div className="util-list-filters">
            <div className="util-tp-filter">
              <p className="util-select-user__label">Select Area Type</p>
              <div className="util-select-user">
                <Select
                  styles={selectUserStyles}
                  options={areaTypeoptions}
                  placeholder="Select Area Type"
                  className="mt-3"
                  value={areaType}
                  onChange={(e) => setAreaType({value : e.value, label: e.label})}
                />
              </div>
            </div>
            {loggedIn?.user?.des !== 121 ? (
            <div className="util-tp-filter">
              <p className="util-select-user__label">Select User</p>
              <div className="util-select-user">
                <Select
                  styles={selectUserStyles}
                  options={employeeSelect}
                  placeholder="Select User"
                  className="mt-3"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser({ ...e })}
                />
              </div>
            </div>
            ) : null}
          </div>
        <section
          className="area-creation-content__info"
          style={{ marginTop: "-4rem" }}
        >
          <div className="filter-table">
        { loading && <h3 className="mt-2 fetching-results-message">Hold on fetching reports for you...</h3>}
            {!loading && data?.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({data?.length}) Entries
              </h2>
            )}
            {!loading && tableData2.length > 0 ? (
              <Table columns={columns} data={tableData2} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} callback={ListExport} fileSrc="Doctors / Stockists / Chemists List" />
            ) : (
                !loading && tableData2.length === 0 && (<h3 className="mt-2 no-results-message">No results found</h3>)
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default HQ_City_LocalArea;
