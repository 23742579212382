import { useNavigate } from "react-router-dom";
import {
  MdOutlineMap,
  MdOutlineDownloading,
  MdOutlineVerified,
  MdOutlineSummarize,
  MdMedicalServices,
} from "react-icons/md";
import {
  RiStethoscopeFill,
  RiCapsuleFill,
  RiFileListFill,
} from "react-icons/ri";
import {
  FaExpandArrowsAlt,
  FaMoneyCheckAlt,
  FaFilePrescription,
  FaPrescriptionBottleAlt,
} from "react-icons/fa";
import { GiTargeted } from "react-icons/gi";
import { GiMedicines } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";

const Card = ({ title, blurb, link, iconName }) => {
  let navigate = useNavigate();

  return (
    <div className="col-sm-12 col-md-6 col-lg-6" onClick={() => navigate(link)}>
      <div className="utility-cards">
        <div className="utility-cards__heading">
          {iconName === "tp" ? (
            <MdOutlineMap />
          ) : iconName === "doc" ? (
            <RiStethoscopeFill />
          ) : iconName === "chem" ? (
            <RiCapsuleFill />
          ) : iconName === "stk" ? (
            <MdMedicalServices />
          ) : iconName === "sales" ? (
            <FaMoneyCheckAlt />
          ) : iconName === "ead" ? (
            <FaExpandArrowsAlt />
          ) : iconName === "lists" ? (
            <RiFileListFill />
          ) : iconName === "target" ? (
            <GiTargeted />
          ) : iconName === "missedDoc" ? (
            <MdOutlineSummarize />
          ) : iconName === "pobRep" ? (
            <FaFilePrescription />
          ) : iconName === "stkWise" ? (
            <FaPrescriptionBottleAlt />
          ) : iconName === "prodWise" ? (
            <GiMedicines />
          ) : iconName === "cr" ? (
            <RiStethoscopeFill />
          ) : iconName === "location" ? (
            <FaLocationDot/>
          ) : iconName === "expense" ? (
            <TbReportAnalytics/>
          ) : null}
          <h3 className="web-app__heading">{title}</h3>
        </div>
        <div className="utility-cards__content">
          <div className="utility-cards__content-info utility-cards__content-info-one">
            <MdOutlineDownloading />
            <p>Can be downloaded as an Excel and PDF</p>
          </div>

          <div className="utility-cards__content-info utility-cards__content-info-two">
            <MdOutlineVerified />
            <p>Approved by your Managers</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
