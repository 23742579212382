import { Outlet } from "react-router";
import Header from "./Layout/Header";
import NavLeft from "./Layout/NavPane";
import { ToastContainer } from "react-toastify";
import Announcement from "./Layout/Announcement";
import PaymentDue from './Layout/Announcement/PaymentDue'
import "../public/stylesheets/app.css";
import { toast as rt } from "react-toastify";

const Home = () => {

  return (
    <div className="web-app">
      <PaymentDue />
      <Announcement />
      <Header className="header" />

      <div className="content">
        <NavLeft />

        <Outlet />

        
        <ToastContainer />
      </div>
    </div>
  );
};

export default Home;
