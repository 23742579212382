import { useEffect, useState, useMemo } from "react";
import { viewProducts } from "../../../../../services/products";
import serialise from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdDoneAll } from "react-icons/md";
import { MdClear } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import ListExport from "../../../../../components/ListExport";

const ProductsList = () => {
  const navigate = useNavigate();
  const [prod, setProd] = useState([]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Division",
        accessor: "division",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Packaging",
        accessor: "packaging",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "MRP",
        accessor: "mrp",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: "PTS",
        accessor: "pts",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: "PTR",
        accessor: "ptr",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: "Images",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => (
          <span>
            {props?.row?.original?.images?.length > 0 ? (
              <MdDoneAll style={{ color: "#2ecc71" }} />
            ) : (
              <MdClear style={{ color: "#e74c3c" }} />
            )}
          </span>
        ),
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
        minWidth: 40,
        maxWidth: 40,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    viewProducts("").then((response) => {
      setProd(response?.data);
    });
  }, []);
  const tableData = useMemo(() => serialise(prod), [prod]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate("/utilities")}
          />
          <h2 className="web-app__heading">Products List</h2>
        </section>

        <section
          className="area-creation-content__info"
          style={{ marginTop: "-4rem" }}
        >
          <div className="filter-table">
            {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
            )}
            {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} exportExcel={false} callback={ListExport} />
            ) : (
              <h3 className="no-results-message">No results found</h3>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default ProductsList;
