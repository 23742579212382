import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";



import { useLocation, useNavigate } from "react-router-dom";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import customToast from "../../../../../../components/CustomToast";
import PageTitle from "../../../../../../components/PageTitle";
import { setCities } from "../../../../../../reducers/locations/cityReducer";
import { clearHeadquarters, setHeadquarters } from "../../../../../../reducers/locations/hqReducer";
import { deleteDoctor, editDoctor } from "../../../../../../reducers/targets/doctor";
import { formatDate } from "../../../../../../utils/helper";
import { generateSelectData } from "../../../../../../utils/serialiseResponse";
import { adminStyles } from "../../../../../public/stylesheets/selectStyles";
import { doctorCategoryOptions } from "../../../../../../utils/helper";


const CreationEditDoctor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);

  const [name, setName] = useState("");
  const [degree, setDegree] = useState("");
  const [specialization, setSepecialization] = useState("");

  const [birthday, setBirthday] = useState();
  const [docAnniversary, setDocAnniversary] = useState();
  const [mobile, setMobile] = useState("");
  const [contact, setContact] = useState("");

  const [doctorCode, setDoctorCode] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState();

  const [address, setAddress] = useState("");

  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState();
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setName(location.state?.name);
    setDegree(location.state?.degree);
    setSepecialization(location.state?.specialization);
    setBirthday(location.state?.birthday);
    setDocAnniversary(location.state?.anniversary);
    setMobile(location.state?.mobile);
    setContact(location.state?.clinicContact);
    setDoctorCode(location.state?.doctorCode);
    setEmail(location.state?.email);
    setCategory(location.state?.category);
    setAddress(location.state?.address);
    setHeadquarter({value:location?.state?.city?._id, label: location?.state?.city?.name});
    setCity({value:location?.state?.workingArea?._id, label: location?.state?.workingArea?.name});
    
  }, []);

  // setName(location.state?.name);

  useEffect(() => {
    dispatch(setHeadquarters());
    return ()=> {dispatch(clearHeadquarters());}
  }, [dispatch]);

  useEffect(() => {
    if (!headquarter) return;

    dispatch(setCities(headquarter?.value));
  }, [dispatch, headquarter]);

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(
    () => generateSelectData(cities.data, "name"),
    [cities]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    try {
      let data = {
        name,
        degree,
        specialization,
        birthday,
        anniversary: docAnniversary,
        email,
        mobile,
        contact,
        doctorCode,
        category,
        address,
        city: headquarter?.value,
        workingArea: city?.value,
      };

      await dispatch(editDoctor(location.state._id, data));
    } catch (e) {
      error = true;
      customToast.error("We Encountered an Error");
    }

    if (!error) {
      customToast.success("Doctor edited Successfully");

      setName("");
      setDegree("");
      setSepecialization("");
      setBirthday("");
      setMobile("");
      setContact("");
      setDoctorCode("");
      setEmail("");
      setAddress("");
      setHeadquarter({value:headquarter?.value, label: headquarter?.label});
      setCity({value:city?.value, label: city?.label});
      setCategory(null);
      navigate(-1);
    }
  };
  const EditPopup = () => {
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Are you sure you want to delete?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
              
                type="button"
                onClick={() => {
                  dispatch(deleteDoctor(location?.state?._id));
                  navigate(-1);
                }}
                className="button-delete mt-4"
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                onClick={() => setShowEdit(false)}
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
 
  return (
    <main className="main-content admin-content">
      <div className="admin-creation-content">
        <PageTitle title= {`Edit Doctor ${location?.state?.name}` } hierarchy={true} />

        <section className="admin-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="admin-form">
              <div>
                <label htmlFor="name">Name</label>
                <br />
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="name"
                  placeholder={`${location?.state?.name}`}
                  name="name"
                />
              </div>
              <div>
                <label htmlFor="degree">Degree</label>
                <br />
                <input
                  value={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  type="text"
                  className="me-5"
                  id="degree"
                  placeholder={`${location.state?.degree}`}
                  name="degree"
                />
              </div>
              <div>
                <label htmlFor="specialization">Specialization</label>
                <br />
                <input
                  value={specialization}
                  onChange={(e) => setSepecialization(e.target.value)}
                  type="text"
                  className="me-5"
                  id="specialization"
                  placeholder={`${location.state?.specialization}`}
                  name="specialization"
                />
              </div>
              <div>
                <label htmlFor="birthday">
                  Birthday{" "}
                  <span className="admin-highlighted-span">
                    {formatDate(location?.state?.birthday)}
                  </span>
                </label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  name="birthday"
                />
              </div>
              <div>
                <label htmlFor="birthday">
                  Marriage Anniversary
                  <span className="admin-highlighted-span">
                    {formatDate(location?.state?.anniversary)}
                  </span>
                </label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setDocAnniversary(e.target.value)}
                  value={docAnniversary}
                  name="birthday"
                />
              </div>
              <div>
                <label htmlFor="mobNumber">Mobile Number</label>
                <br />
                <input
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="mobNumber"
                  placeholder={`${location.state?.mobile}`}
                  name="mobNumber"
                />
              </div>
              <div>
                <label htmlFor="contact">Clinic's Contact Number </label>
                <br />
                <input
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="contact"
                  placeholder={`${location.state?.clinicContact}`}
                  name="clinicContact"
                />
              </div>
              <div>
                <label htmlFor="doctorCode">Doctor's Code</label>
                <br />
                <input
                  value={doctorCode}
                  onChange={(e) => setDoctorCode(e.target.value)}
                  type="text"
                  className="me-5"
                  id="doctorCode"
                  placeholder={`${location.state?.doctorCode}`}
                  name="doctorCode"
                />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <br />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="me-5"
                  id="email"
                  placeholder={`${location.state?.email}`}
                  name="email"
                />
              </div>
              <div>
                <label htmlFor="doctorCategory">Category</label>
                <br />
                <Select
                  name="doctorCategory"
                  id="doctorCategory"
                  value={category}
                  options={doctorCategoryOptions}
                  onChange={(e) =>
                    setCategory({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder={`${location.state?.category?.label}`}
                />
              </div>
              {/* {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.isAdmin ? (
                <div>
                  <label htmlFor="hq">
                    Select MR to Allot 
                  </label>
                  <br />
                  <Select
                    name="mrAllot"
                    id="mrAllot"
                    value={selectedUser}
                    options={employeeSelect}
                    onChange={(e) => setSelectedUser({ ...e })}
                    styles={adminStyles}
                    placeholder="Select MR"
                  />
                </div>
              ) : null} */}
              <div>
                <label htmlFor="hq">
                  Current HQ:{" "}
                  <span className="admin-highlighted-span">
                    {location.state?.city?.name}
                  </span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqSelect}
                  onChange={(e) =>
                    setHeadquarter({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder={`${location.state?.city?.name}`}
                />
              </div>
              <div>
                <label htmlFor="hq">
                  Current Working Area:{" "}
                  <span className="admin-highlighted-span">
                    {location.state?.workingArea?.name}
                  </span>
                </label>
                <br />
                <Select
                  name="city"
                  id="city"
                  value={city}
                  options={citySelect}
                  onChange={(e) => setCity({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder={`${location.state?.workingArea?.name}`}
                />
              </div>
              <div>
                <label htmlFor="address">Clinic's Address</label>
                <br />
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="1"
                  placeholder={`${location.state?.address}`}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <br />
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <button type="submit" className="button-submit-green me-5 mt-5">
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>
              
            </div>
          </form>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default CreationEditDoctor;
