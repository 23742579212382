import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import Table from "../../../../components/Table";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import serialise, {
  generateSelectData, getDesignation,
} from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import PageTitle from "../../../../components/PageTitle";
import { FaEye } from "react-icons/fa";
import { MdArrowBack, MdArrowBackIosNew } from "react-icons/md";

const AdminInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const divisionData = useSelector(({ division }) => division);
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const userData = useSelector(({ employee }) => employee);

  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );
  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState(null);
  const [division, setDivision] = useState(null);

  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setStates());
    return () => {
      dispatch(clearDivisions());
      dispatch(clearStates());
      dispatch(clearUsers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  const redirectToEdit = (element) => {
    navigate("/admin-panel/users/user-info/details", { state: element });
  };
  useEffect(()=>{
    dispatch(setUsers("user"));
  },[]);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
        Cell: (props) => {
          return <span>{props?.row?.original?.firstName+" "+props?.row?.original?.middleName+" "+props?.row?.original?.lastName}</span>;
        },
      },
      {
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Reporting Manager",
        accessor: "reportingManager.firstName",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 85,
        maxWidth: 85,  
      },
      {
        Header: "Designation",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
        Cell: (props) => {
          return <span>{getDesignation(props?.row?.original?.des)}</span>;
        },
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Division",
        accessor: "division",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        wordWrap:"break-word"
      },
      {
        Header:"Status",
        accessor:"",
        minWidth: 80,
        maxWidth: 80,
        Cell:(props) => {
          let isActive = props?.row?.original?.isActive;

          return isActive ? 
          <div style={{color:"green"}}>Activated</div>
          :
          <div style={{color:"red"}}>De-Activated</div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() => redirectToEdit(props.row.original)}
          >
           <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 40,
        minWidth: 40,
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(userData), [userData]);

  const handleFilter = (e) => {
    e.preventDefault();

     const data = {
      hqId: headquarter?.value,
      divisionId: division?.value,
    };

    try {
      dispatch(setUsers("user", data));
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
          style={{cursor:"pointer"}}
          onClick={() => navigate("/admin-panel")}/>
          <h2 className="web-app__heading">User Info</h2>
          <div style={{color:"var(--color-tertiary)", marginLeft:"auto", cursor:"pointer"}} onClick={() => navigate("../user-info/active-control")}>
            User Activation Control ?
            </div>
        </section>
        {/* <PageTitle title="User Info" /> */}

        <section className="area-creation-content__form">
          <form onSubmit={handleFilter}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select State</label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={({ value, label }) => setState({ value, label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select HQ</label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={({ value, label }) =>
                    setHeadquarter({ value, label })
                  }
                  styles={adminStyles}
                  placeholder={
                    state ? "Select Headquarter" : "Select State First"
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Division</label>
                <br />
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Filter Users
                </button>
              </div>
            </div>
          </form>
        </section>

        <section  className="area-creation-content__info py-0">

          {tableData.length > 0 && (
            <h2 className="web-app__heading mt-5 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
          {tableData.length > 0 ? (
            <Table columns={columns} data={tableData} />
          ) : (
            <h3 className="no-results-message">No results found</h3>
          )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdminInfo;
