import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import react,{ useMemo } from "react";
import serialise from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { FaEye } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import customToast from "../../../../../../components/CustomToast";
import { viewHQwisePrimarySales } from "../../../../../../services/utilities";
import { formatDate } from "../../../../../../utils/helper";


const HeadquarterPS = ({render}) => {
  
  const [reports, setReports] = useState([]);
  const [toggle ,  setToggle] = useState(false);
  const [loading , setLoading] = useState(false);
  const dateRange = useSelector(({primarySalesReports})=>primarySalesReports.dateRange);

  const fetchHQ = useCallback(
    async()=>{
      setLoading(true);
      try {
          const filter = {
            type:toggle?"date":"sales",
            startDate: new Date(dateRange?.startDate).toISOString(),
            endDate: new Date(dateRange?.endDate).toISOString()
          };
          const {data} = await viewHQwisePrimarySales(filter);
          setReports(data);
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
      setLoading(false);
    },[dateRange,toggle]
  )
  useEffect(()=>{
    if(!loading){
      fetchHQ();
    }
  },[fetchHQ,toggle,render]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      //   disableSortBy:true,
      // },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={"./stk"}
              state={{ data: props?.row?.original?._id, dateRange:dateRange }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [dateRange]
  );
  const columns2 = useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: "sr",
        disableFilters:true,
        disableSortBy:true,
        minWidth:50,
        maxWidth:50
      },
      {
        Header:"Date",
        accessor:"date",
        minWidth:100,
        maxWidth:100,
        disableFilters:true,
        disableSortBy:true,
        Cell:(props)=>(
          <span>{formatDate(props?.row?.original?.date)}</span>
        )
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={"../stk"}
              state={{ data: props?.row?.original?._id, 
                dateRange:{startDate:props?.row?.original?.date,endDate:props?.row?.original?.date}
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(reports), [reports]);
  
  
  return (
    <div className="filter-table">
      { loading && <h3 className="mt-2 fetching-results-message">Hold on fetching reports for you...</h3>}
      {tableData.length > 0 && (
        <>
        <h2 className="web-app__heading mb-4 ps-3 d-flex justify-content-between">
            Showing ({reports.length}) Entries
            <div className="form__control pe-3" style={{ width: "fit-content" }}>
              <label className="toggle-label me-3">
                <input
                  type="checkbox"
                  defaultChecked={toggle}
                  onClick={(e)=>setToggle(!toggle)}
                />
                <span/>
              </label>
              <span>View Date Wise</span>
            </div>
        </h2>
        <Table columns={toggle ? columns2 : columns} data={tableData} fileSrc="Primary Sales Report (Headquarter)" />
        </>
        ) 
      }
      {
        !loading && tableData.length === 0 && (<h3 className="mt-2 no-results-message">No results found</h3>)
      }
    </div> 
  );
};

export default HeadquarterPS ;