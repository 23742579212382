import Cookies from 'js-cookie';

export const storeUser = (user, expires) => {
	Cookies.set('loggedUser', JSON.stringify(user), { expires });
};

export const retrieveUser = () => {
	const storedJSON = Cookies.get('loggedUser');
	const loggedUser = storedJSON ? JSON.parse(storedJSON) : null;

	return loggedUser;
};

export const retrieveToken = () => {
	const storedJSON = Cookies.get('loggedUser');
	const loggedUser = storedJSON ? JSON.parse(storedJSON) : null;

	if (loggedUser) return loggedUser.token;
	else return null;
};

export const clearUser = () => {
	Cookies.remove('loggedUser');
};
