import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";


import { useLocation,useNavigate } from "react-router-dom";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";


import customToast from "../../../../../../components/CustomToast";
import PageTitle from "../../../../../../components/PageTitle";
import { setCities } from "../../../../../../reducers/locations/cityReducer";
import { clearHeadquarters, setHeadquarters } from "../../../../../../reducers/locations/hqReducer";
import { generateSelectData } from "../../../../../../utils/serialiseResponse";
import { deleteChemist, editChemist } from "../../../../../../reducers/targets/chemist";
import { formatDate } from "../../../../../../utils/helper";
import { adminStyles } from "../../../../../public/stylesheets/selectStyles";

const CreationEditChemist = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);

  const [businessName, setBusinessName] = useState("");
  const [name, setName] = useState("");
  const [certification, setCertification] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [chemistContact, setChemistContact] = useState("");
  const [address, setAddress] = useState("");
  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState();
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setBusinessName(location.state?.businessName);
    setName(location.state?.name);
    setCertification(location.state?.certification);
    setEmail(location.state?.email);
    setBirthday(location.state?.birthday);
    setChemistContact(location.state?.chemistContact);
    setAddress(location.state?.address);
    setHeadquarter({value:headquarter?.value, label: headquarter?.label});
    setCity({value:city?.value, label: city?.label});
  }, []);


  useEffect(() => {
    dispatch(setHeadquarters());
    return ()=> {dispatch(clearHeadquarters());}
  }, [dispatch]);

  useEffect(() => {
    if (!headquarter) return;

    dispatch(setCities(headquarter?.value));
  }, [dispatch, headquarter]);

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(
    () => generateSelectData(cities.data, "name"),
    [cities]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    try {
      let data = {
        businessName,
        name,
        certification,
        birthday: birthday,
        email,
        chemistContact: chemistContact,
        address: address,
        city: headquarter?.value,
        workingArea: city?.value,
      };

      await dispatch(editChemist(location.state._id, data));
    } catch (e) {
      error = true;
      customToast.error("We Encountered an Error");
    }

    if (!error) {
      customToast.success("Chemist Edited Successfully");

      setName("");
      setCertification("");
      setBirthday("");
      setChemistContact("");
      setAddress("");
      setHeadquarter({value:headquarter?.value, label: headquarter?.label});
      setCity({value:city?.value, label: city?.label});
      navigate(-1);
    }
  };
  const EditPopup = () => {
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Are you sure you want to delete?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
              
                type="button"
                onClick={() => {
                  dispatch(deleteChemist(location?.state?._id));
                  navigate(-1);
                }}
                className="button-delete mt-4"
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                onClick={() => setShowEdit(false)}
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  return (
    <main className="main-content admin-content">
      <div className="admin-creation-content">
        <PageTitle
          title={`Edit Chemist ${location?.state?.businessName}`}
          hierarchy={true}
        />

        <section className="admin-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="businessName">Business Name</label>
                <br />
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="businessName"
                  placeholder="Enter Chemist's Name"
                  name="businessName"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="name">Propreitor Name</label>
                <br />
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="name"
                  placeholder="Enter Chemist's Name"
                  name="name"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="certifications">
                  Certifications / Trademarks{" "}
                </label>
                <br />
                <input
                  value={certification}
                  onChange={(e) => setCertification(e.target.value)}
                  type="text"
                  className="me-5"
                  id="degree"
                  placeholder="Enter Certification"
                  name="degree"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="birthday">
                  Birthday{" "}
                  <span className="admin-highlighted-span">
                    {formatDate(location?.state?.birthday)}
                  </span>
                </label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  name="birthday"
                />{" "}
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  onChange={({ target }) => setEmail(target.value)}
                  type="email"
                  className="me-5"
                  id="email"
                  placeholder="Enter Email Address"
                  name="email"
                  value={email}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="contact">Chemist's Contact Number </label>
                <br />
                <input
                  value={chemistContact}
                  onChange={(e) => setChemistContact(e.target.value)}
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="contact"
                  placeholder="Enter Alternate Number"
                  name="clinicContact"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mt-5">
                <label htmlFor="address">Chemist's Address</label>
                <br />
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="5"
                  placeholder="Enter Clinic Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="hq">
                    Current HQ:{" "}
                    <span className="admin-highlighted-span">
                      {location.state?.city?.name}
                    </span>
                  </label>
                  <br />
                  <Select
                    name="hq"
                    id="hq"
                    value={headquarter}
                    options={hqSelect}
                    onChange={(e) => setHeadquarter({ ...e })}
                    styles={adminStyles}
                    placeholder={`${location.state?.city?.name}`}
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="city">
                    Current Working Area:{" "}
                    <span className="admin-highlighted-span">
                      {location.state?.workingArea?.name}
                    </span>
                  </label>
                  <br />
                  <Select
                    name="city"
                    id="city"
                    value={city}
                    options={citySelect}
                    onChange={(e) => setCity({ ...e })}
                    styles={adminStyles}
                    placeholder={`${location.state?.workingArea?.name}`}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap mt-4 admin-creation-content__form-submit d-flex flex-wrap">
                <button type="submit" className="button-submit-green me-5 mt-5">
                  <span className="button-submit-green__icon-container">
                    <BsCheck2Circle className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text">Edit </span>
                </button>
                
              </div>
            </div>
          </form>
        </section>
        {showEdit?<EditPopup/>:null}
      </div>
    </main>
  );
};

export default CreationEditChemist;
