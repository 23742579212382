import StyledCalendar from "../../../components/Calendar";
import { useState, useEffect, useMemo } from "react";
import { selectStyles } from "./../../public/stylesheets/selectStyles.js";
import { MdDelete,MdDeleteOutline,MdCheckCircleOutline } from "react-icons/md";
import {BsCheck2All} from "react-icons/bs" ;
import {AiOutlineClose, AiOutlineStop} from "react-icons/ai";
import { IoNotificationsCircleOutline } from "react-icons/io5";
import {
  getDistance,
  getExpenseForToday,
  getTotalMonthlyExpense,
} from "../../../services/expenses";
import { useSelector, useDispatch } from "react-redux";
import customToast from "../../../components/CustomToast";
import {
  clearExpense,
  newExpense,
  viewExpense,
} from "../../../reducers/expenses/expense";
import { deleteExpense } from "../../../services/expenses";
import { formatDate, monthOptions, yearOptions } from "../../../utils/helper";
import Select from "react-select";
import {
  dcrStyles,
  selectUserStyles,
} from "./../../public/stylesheets/selectStyles.js";
import Table from "../../../components/Table";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../services/utilities";
import { MdOutlineGroup, MdAttachMoney, MdArrowForwardIos, MdArrowBackIos, MdCameraAlt, MdInfoOutline, MdArrowBackIosNew } from "react-icons/md";
import { viewTPForDate } from "./../../../services/tourProgram.js";
import moment from "moment-timezone";
import { RiCloseCircleLine } from "react-icons/ri";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; 
import { hideSidenav, showSidenav, showTrimNav } from "../../../reducers/appReducer.js";

const Expenses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  const expenseData = useSelector(({ expense }) => expense);

  const [date, setDate] = useState(new Date());
  const [travelAllowance, setTravelAllowance] = useState(0);
  const [ticketAllowance, setTicketAllowance] = useState(0);
  const [dailyAllowance, setDailyAllowance] = useState(0);
  const [foodAllowance, setFoodAllowance] = useState(0);
  const [hotelAllowance, setHotelAllowance] = useState(0);
  const [misExpense, setMisExpense] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [tpData, setTpData] = useState({});
  const [totalExpense, setTotalExpense] = useState({});
  const [found, setFound] = useState(false);
  const [showEdit, setShowEdit]=useState(false);
  const [month, setMonth] = useState(monthOptions[new Date().getMonth()]);
  const [year, setYear] = useState(yearOptions[2]);
  const [expenseId, setExpenseId] = useState("");
  const [imageShow, setImageShow] = useState(false);
  const [image, setImage] = useState();
  const [showRemark,setShowRemark] = useState(false);
  const [remarkData,setRemarkData] = useState();
  const [filter, setFilter] = useState({value:1, label:"Approved"});
  const [filteredData,  setFilteredData] = useState([]);
  const [popupType, setPopupType] = useState();

  useEffect(() =>{
    dispatch(showTrimNav());

    return ()=> dispatch(showSidenav());
  },[dispatch]);

  const filterOptions = useMemo(() => [
    { value: 1, label: "Approved" },
    { value: 0, label: "Pending" },
    { value: -1, label: "Rejected" },
    { value: -3, label: "Not Submitted" },
    { value: 2, label: "All" },
  ]
    , []);
  const ImagePopup = ({ data }) => {
    return (
      <section className="edit-popup" onClick={(e) =>
        imageShow
          ? e.target.className === "edit-popup"
            ? setImageShow(false)
            : null
          : null
      }>
        <Carousel
          slide={false}
          interval={null}
          nextIcon={<MdArrowForwardIos style={{ fontSize: "2em" }} />}
          prevIcon={<MdArrowBackIos style={{ fontSize: "2em" }} />}
        >
          {data?.files && data?.files.map((e) => {
            return (
              <Carousel.Item>
                <img src={e} alt="Expense Img" />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </section>
    );
  };
  const RemarkPopup = ({ data, type }) => {
    return (
      <section className="edit-popup" onClick={(e) =>
        showRemark
          ? e.target.className === "edit-popup"
            ? setShowRemark(false)
            : null
          : null
      }>
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">{type}</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowRemark(false)}
            />
          </div>
          {type === "remark" && (
            <>
              User remark : {data?.remarks}
              <br />
              {data.adminRemark && (
                <span>Admin/Manager remark : {data?.adminRemark}</span>
              )}
            </>
          )}
          {type === "route" && (
            <>
              <br />
              <h3>Work Areas:</h3>
              {data?.workAreas?.length === 0 ? (
                <p> Local</p>
              ) : (
                <ul>
                  {data?.workAreas?.map((workArea, index) => (
                    <li key={index}>{workArea?.location?.label}</li>
                  ))}
                </ul>
              )}
            </>
          )}

        </div>
      </section>
    );
  };
  const EditPopup = () => {
    let filterData = {
      selectedUser: selectedUser?.value,
      month: month?.value,
      year: Number(year?.label),
    };
    const handleDelete = (e) => {
      e.preventDefault();
      deleteExpense(expenseId).then(() => {
        customToast.success("Expense deleted successfully");
        dispatch(viewExpense(filterData));
        getTotalMonthlyExpense(filterData).then((res) =>
          setTotalExpense(res.data)
        );
      }).catch((e) => {
        customToast.error("It seems we encountered and error!");
      });
      setShowEdit(false);
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Expense</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to PERMANENTLY delete this Expense?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  const employeeSelect = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );



  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        showTotal: true,
        minWidth: 90,
        maxWidth: 90,
        placeholderTotal: "Total",
        disableFilters: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
          // if(day === "Sun") return <span className="opacity-25">{formatDate(props.row.original?.date)}</span>
          return props.row.original?.isApproved === 1 ? (
            <span className="approved-text fw-bold">{formatDate(props.row.original?.date)}</span>
          ) : props.row.original?.isApproved === 0 ? (
            <span className="pending-text fw-bold">{formatDate(props.row.original?.date)}</span>
          ) : props.row.original?.isApproved === -1 ? (
            <span className="rejected-text fw-bold">{formatDate(props.row.original?.date)}</span>
          ) : day === "Sun" ? (
            props?.row?.original._id ? <span className="notsubmitted-text fw-bold">{formatDate(props.row.original?.date)}</span> 
          : <span className="opacity-25">{formatDate(props.row.original?.date)}</span>
        ) 
          : <span className="notsubmitted-text fw-bold">{formatDate(props.row.original?.date)}</span>

        },
      },
      {
        Header: "Day",
        accessor: "day",
        disableSortBy: true,
        disableFilters: true,
        wordWrap: "break-word",
        minWidth: 70,
        maxWidth: 70,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
          if(day === "Sun" ) return props?.row?.original._id ? <span>{day}</span>
              : <span className="opacity-25">{day}</span>

          return <span>{day}</span>
        },
      },
      {
        Header: "Area Type",
        accessor: "areaType",
        // disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (

            // <button
            //   className="react-table-view-link bg-transparent border-0"
            //   onClick={() => {
            //     setRemarkData(props.row.original)
            //     setPopupType("route")
            //     setShowRemark(true);
            //   }}
            // >
            //   { props?.row?.original.areaType}
            // </button>
            <span> {props?.row?.original.areaType}</span>
          );
        },
      },
      {
        Header: "Working Type",
        accessor:"",
        accessor: (row) => {
          let day = new Date(row?.date).toLocaleString('en-us', { weekday: 'short' });
          if(day === "Sun") return <span></span>
          if (row.holiday) return "Holiday";
          if (row.leave || row.activity === 'leave') return "Leave";
          if (row.activity) return row.activity.charAt(0).toUpperCase() + row.activity.slice(1);
          return "Working";
        },
        // disableFilters: true,
        disableSortBy: true,
        filter:'text',
        Cell: ({value}) => {
          // if (props?.row?.original?.holiday) {
          //   return <span>Holiday</span>;
          // }
          // else if (props?.row?.original?.leave || props?.row?.original?.activity == 'leave') {
          //   return <span>Leave</span>;
          // }
          // else if (props?.row?.original?.activity) {
          //   return <span style={{textTransform:"capitalize"}}>{props?.row?.original?.activity}</span>;
          // }
          // else {
            return <span>{value}</span>
          // }
        }
      },
      {
        Header: "Total Calls",
        accessor: "totalCalls",
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) => {
          let data = props?.row?.original;
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
          if(day === "Sun") return props?.row?.original._id ?  (props?.row?.original?.totalCalls) 
            : <span></span>

          if(data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1) return <span></span>
          return props?.row?.original?.totalCalls;
        }
      },
      {
        Header: "Work Areas",
        accessor: "workAreas",
        // disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const workAreas = props?.row?.original?.workAreas;
          return (
            <div>
              {(workAreas?.length === 0) ? (
                <p>Local</p>
              ) : (
                <ul>
                  {workAreas?.map((workArea, index) => (
                    <li key={index}>{workArea?.location?.label}</li>
                  ))}
                </ul>
              )}
            </div>
          );
        },
      },
      // {
      //   Header: "Travel",
      //   accessor: "travelAllowance",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   showTotal: true,
      //   totalAccessor: "travelAllowance"
      // },
      // {
      //   Header: "Food",
      //   accessor: "foodAllowance",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   showTotal: true,
      //   totalAccessor: "foodAllowance"
      // },
      // {
      //   Header: "Hotel",
      //   accessor: "hotelAllowance",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   showTotal: true,
      //   totalAccessor: "hotelAllowance"
      // },
      // {
      //   Header: "Ticket",
      //   accessor: "ticketAllowance",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   showTotal: true,
      //   totalAccessor: "ticketAllowance"
      // },
      // {
      //   Header: "Daily",
      //   accessor: "dailyAllowance",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   showTotal: true,
      //   totalAccessor: "dailyAllowance"
      // },
      // {
      //   Header: "Misc.",
      //   accessor: "misExpense",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   showTotal: true,
      //   totalAccessor: "misExpense",
      // },
      // {
      //   Header: "Total",
      //   accessor: "",
      //   disableFilters: true,
      //   Cell: (props) => {
      //     return (
      //       props.row.original?.travelAllowance +
      //       Number(props.row.original?.misExpense) +
      //       props.row.original?.dailyAllowance +
      //       props.row.original?.foodAllowance +
      //       props.row.original?.hotelAllowance +
      //       Number(props.row.original?.ticketAllowance)
      //     );
      //   },
      // },
      {
        Header: "Travel",
        accessor: "travelAllowance",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "travelAllowance",
        Cell:(props) => {
            let data = props?.row?.original;

            let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });

            if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

            let value = Number(data?.travelAllowance);
            if(!value) return <span>--</span>
            if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
            return value;
        },
      },
      {
        Header: "Food",
        accessor: "foodAllowance",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "foodAllowance",
        Cell:(props) => {
            let data = props?.row?.original;

            let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
            if(day === "Sun") return <span></span>

            if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

            let value = Number(data?.foodAllowance);
            if(!value) return <span>--</span>
            if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
            return value;
        },
      },
      {
        Header: "Hotel",
        accessor: "hotelAllowance",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "hotelAllowance",
        Cell:(props) => {
            let data = props?.row?.original;

            let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
            if(day === "Sun") return <span></span>

            if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

            let value = Number(data?.hotelAllowance);
            if(!value) return <span>--</span>
            if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
            return value;
        },
      },
      {
        Header: "Ticket",
        accessor: "ticketAllowance",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "ticketAllowance",
        Cell:(props) => {
            let data = props?.row?.original;

            let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
            if(day === "Sun") return <span></span>

            if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;
            let value = Number(data?.ticketAllowance);
            if(!value) return <span>--</span>
            if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
            return value;
        },
      },
      {
        Header: "Daily",
        accessor: "dailyAllowance",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "dailyAllowance",
        Cell:(props) => {
            let data = props?.row?.original;

            let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
            if(day === "Sun") return <span></span>

            if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

            let value = Number(data?.dailyAllowance);
            if(!value) return <span>--</span>
            if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
            return value;
        },
      },
      {
        Header: "Misc.",
        accessor: "misExpense",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "misExpense",
        Cell:(props) => {
            let data = props?.row?.original;

            let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
            if(day === "Sun") return <span></span>

            if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

            let value = Number(data?.misExpense);
            if(!value) return <span>--</span>
            if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
            return value;
        },
      },
      {
        Header: "Total",
        accessor: "",
        disableFilters: true,
        Cell: (props) => {
            let data = props?.row?.original;
            let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
            if(day === "Sun") return <span></span>;

            if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

            let value = (data?.travelAllowance +
                Number(data?.misExpense) +
                data?.dailyAllowance +
                data?.foodAllowance +
                data?.hotelAllowance +
                Number(data?.ticketAllowance));

            if(!value)  return <span>--</span>    
            if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
          return value;
        },
      },
      {
        Header: "Remarks",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
          if(day === "Sun") return props?.row?.original._id ? (
            <button
              disabled={!props?.row?.original?.remarks?.length && !props?.row?.original?.adminRemark?.length}
              className="react-table-view-link bg-transparent border-0"
              onClick={() => {
                setRemarkData(props.row.original);
                setPopupType("remark")
                setShowRemark(true);
              }}
            >
              {(props?.row?.original?.adminRemark?.length > 0) ?
                <IoNotificationsCircleOutline className={`icon-color-yellow ${!props?.row?.original?.adminRemark?.length && "opacity-25"}`} />
                :
                <MdInfoOutline className={`icon-color-tertiary ${!props?.row?.original?.remarks?.length && "opacity-25"}`} />
              }
            </button>
          )
          : <span></span>

          if(!props?.row?.original?.submitter)    return <span></span>;

          return (
            <button
              disabled={!props?.row?.original?.remarks?.length && !props?.row?.original?.adminRemark?.length}
              className="react-table-view-link bg-transparent border-0"
              onClick={() => {
                setRemarkData(props.row.original);
                setPopupType("remark")
                setShowRemark(true);
              }}
            >
              {(props?.row?.original?.adminRemark?.length > 0) ?
                <IoNotificationsCircleOutline className={`icon-color-yellow ${!props?.row?.original?.adminRemark?.length && "opacity-25"}`} />
                :
                <MdInfoOutline className={`icon-color-tertiary ${!props?.row?.original?.remarks?.length && "opacity-25"}`} />
              }
            </button>
          );
        },
      },
      {
        Header: "View",
        accessor: "View",
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
          if(day === "Sun") return props?.row?.original._id ? 
          (
            <button
              disabled={props?.row?.original?.files?.length > 0 ? false : true}
              className="react-table-view-link bg-transparent border-0"
              onClick={() => {
                setImage(props.row.original);
                setImageShow(true);
              }}
            >
              <MdCameraAlt className={`icon-color-tertiary ${props?.row?.original?.files?.length > 0 ? null : "opacity-25"}`} />
            </button>
          ) : <span></span>
        
          if(!props?.row?.original?.submitter)    return <span></span>;

          return (
            <button
              disabled={props?.row?.original?.files?.length > 0 ? false : true}
              className="react-table-view-link bg-transparent border-0"
              onClick={() => {
                setImage(props.row.original);
                setImageShow(true);
              }}
            >
              <MdCameraAlt className={`icon-color-tertiary ${props?.row?.original?.files?.length > 0 ? null : "opacity-25"}`} />
            </button>
          );
        },
        minWidth: 60,
        maxWidth: 60,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Delete",
        accessor: "delete",
        Cell: (props) => {
          return (
            <button
              disabled={loggedIn?.user?.isAdmin ? false : true}
              className="react-table-view-link bg-transparent border-0"
              onClick={() => {
                setShowEdit(true);
                setExpenseId(props?.row?.original?._id);
              }}
            >
              <MdDelete className={`icon-color-error ${loggedIn?.user?.isAdmin ? null : "opacity-25"}`} />
            </button>
          );
        },
        minWidth: 60,
        maxWidth: 65,
        disableFilters: true,
        disableSortBy: true,
      },

    ],
    []
  );

  const resetForm = () => {
    setTravelAllowance(0);
    setDailyAllowance(0);
    setMisExpense(0);
    setRemarks("");
    setTicketAllowance(0);
  };

  useEffect(() => {
    let filterData = {
      selectedUser: selectedUser?.value,
      month: month?.value,
      year: Number(year?.label),
    };
    dispatch(viewExpense(filterData));
    getTotalMonthlyExpense(filterData).then((res) =>
      setTotalExpense(res.data)
    );
    return () => {
      dispatch(clearExpense());
    };
  }, [dispatch, date, month, year, selectedUser]);

  useEffect(() => {
    let formattedDate = moment
      .tz(date, "Asia/Kolkata")
      .format()
      .toString()
      .substring(0, 10);

    const asyncFetch =  () => {

      let data = {
        selectedUser: selectedUser?.value,
        date: formattedDate,
        month: new Date(date).getMonth(),
        year: new Date(date).getFullYear(),
      };

      getDistance(data)
        .then((res) => {
          setDailyAllowance(res.data?.da);
          setTravelAllowance(res.data?.ta);
          setFoodAllowance(res.data?.food);
          setHotelAllowance(res.data?.hotel);
        })
        .catch((err) => console.log(err));

      viewTPForDate({ date: formattedDate }).then((res) => {
        setTpData(res.data);
      }).catch((err) => console.log(err));

      getExpenseForToday({ date }).then((res) => {
        if (res?.data === true) {
          setFound(true);
        } else {
          setFound(false);
        }
      }).catch((err) => console.log(err));


    };

    asyncFetch();
  }, [date, selectedUser]);

  // useEffect(() => {
  //   console.log(tpData)
  //   console.log(loggedIn?.user)
  //   tpData?.areaType?.toLowerCase() === "local" && setDailyAllowance(loggedIn?.user.dailyAllowance)
  //   tpData?.areaType?.toLowerCase() === "ex-station" && setDailyAllowance(loggedIn?.user.exStationAllowance)
  //   tpData?.areaType?.toLowerCase() === "out-station" && setDailyAllowance(loggedIn?.user.outStationAllowance)
  // })

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!date) {
      return customToast.error("Please select the date");
    }

    try {
      const data = {
        date: moment.tz(date, "Asia/Kolkata").format().toString().substring(0, 10),
        travelAllowance,
        dailyAllowance,
        foodAllowance,

        hotelAllowance,
        misExpense: Number(misExpense),
        ticketAllowance,
        areaType:
          tpData?.areaType?.toLowerCase() === "ex-station"
            ? "Ex-Station"
            : tpData?.areaType?.toLowerCase() === "out-station"
              ? "Out-Station"
              : "Local",
        remarks,
        workAreas: tpData?.workAreas,
      };
      let filterData = {
        selectedUser: selectedUser?.value,
        month: month?.value,
        year: Number(year?.label),
      };

      await dispatch(newExpense(data));
      dispatch(viewExpense(filterData));
      customToast.success("Expense successfully sent for approval");
      resetForm();
    } catch (error) {
      customToast.error(error.response.data.data);
    }
  };
  const filterExpenseData = () => {
    let d;
    if (filter.value == 1) {
      d = expenseData?.data?.filter((val) => {
        return (val.isApproved == 1);
      });
    }
    else if (filter.value == 0) {
      d = expenseData?.data?.filter((val) => {
        return (val.isApproved == 0);
      });
    }
    else if (filter.value == -1) {
      d = expenseData?.data?.filter((val) => {
        return (val.isApproved == -1);
      });
    }
    else if (filter.value == -3) {
      d = expenseData?.data?.filter((val) => {
        return (!val.hasOwnProperty('isApproved') && !val.hasOwnProperty('submitter'));
      });
    }
    else {
      d = expenseData?.data;
    }
    return d;
  }
  useEffect(() => {

    const newData = filterExpenseData();
    setFilteredData(newData);
  }, [filter, expenseData]);
  const tableData = useMemo(() => serialise(filteredData), [filteredData]);
  function compareDates(a, b) {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  }
  tableData.sort(compareDates);
  
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);


  return (
    <main className="main-content">
      <div className="expense" >
        <div className="row">
          <h3 className="web-app__heading cursor-pointer" onClick={() => navigate(-1)}><MdArrowBackIosNew /> Feed Expense</h3>

          <div>
            {!loggedIn?.user?.isAdmin ? (
              <div className={tpData && Object.keys(tpData).length ? "tp-details-box" : "tp-details-box-red"}>
                {tpData && Object.keys(tpData).length ? (
                  <>
                    <div className="tp-details-box__area-type">
                      <h3>Working Area Type</h3>
                      <p>{tpData?.areaType || "__"}</p>
                    </div>

                    <div>
                      <h3>Working Areas</h3>
                      <p>
                        {tpData?.workAreas?.length > 0
                          ? tpData?.workAreas?.map((el) => el?.location?.label)
                          : "__"}
                      </p>
                    </div>
                  </>
                ) : (
                  <h3> No Existing Tour Program. Please Create One to Add Expense.</h3>
                )}
              </div>
            ) : null}

            {!loggedIn?.user?.isAdmin ? (
              <div className="expense__filters">
                <form className="" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="calendar-container col-lg-5">
                      <StyledCalendar date={date} onChange={setDate} />
                    </div>

                    <div className="col-md-7 col-lg-7">
                      {tpData?.areaType === "Ex-Station" ? (
                        <div className="expense-form__control">
                          <label htmlFor="ta">Travel Allowance</label>
                          <input
                            type="number"
                            id="ta"
                            disabled
                            placeholder="Travel Allowance"
                            value={travelAllowance}
                          />
                        </div>
                      ) : tpData?.areaType === "Out-Station" ? (
                        <>
                          <div className="expense-form__control">
                            <label htmlFor="ta">Food Allowance</label>
                            <input
                              type="number"
                              id="ta"
                              disabled
                              placeholder="Food Allowance"
                              value={foodAllowance}
                            />
                          </div>
                          <div className="expense-form__control">
                            <label htmlFor="ta">Hotel Allowance</label>
                            <input
                              type="number"
                              id="ta"
                              disabled
                              placeholder="Hotel Allowance"
                              value={hotelAllowance}
                            />
                          </div>
                          <div className="expense-form__control">
                            <label htmlFor="ticket">Ticket Allowance</label>
                            <input
                              type="number"
                              id="ticket"
                              placeholder="Ticket Allowance"
                              value={ticketAllowance}
                              onChange={(e) =>
                                setTicketAllowance(e.target.value)
                              }
                            />
                          </div>
                        </>
                      ) : null}

                      <div className="expense-form__control">
                        <label htmlFor="da">Daily Allowance</label>
                        <input
                          type="number"
                          id="da"
                          disabled
                          placeholder="Daily Allowance"
                          value={dailyAllowance}
                        />
                      </div>

                      <div className="expense-form__control">
                        <label htmlFor="misExpense">
                          Miscellaneous Expense
                        </label>
                        <input
                          type="number"
                          id="misExpense"
                          placeholder="Enter Amount"
                          value={misExpense}
                          onChange={(e) => setMisExpense(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 expense-remarks">
                      <label htmlFor="remarks">Remarks</label>
                      <textarea
                        name="remarks"
                        id="remarks"
                        cols="30"
                        rows="1"
                        placeholder="Enter Remarks"
                        value={remarks}
                        onChange={({ target }) => setRemarks(target.value)}
                      ></textarea>
                    </div>

                    <div className="col-sm-3 col-md-3 col-lg-3 mt-4">
                      {tpData && Object.keys(tpData).length && !found ? (
                        <button type="submit" className="button-blue-gradient">
                          Submit Expense
                        </button>
                      ) : (
                        <button disabled className="button-disabled">
                          {tpData && Object.keys(tpData).length ? "Expense Already Exists" : "No Tour Program"}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            ) : null}

            <form>
              <div className="expense__filters">
                <div className="expense__filters-duration">
                  <div className="me-4">
                    <p>
                      Select Month <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <Select
                      styles={dcrStyles}
                      placeholder="Select Month"
                      className="mt-3"
                      options={monthOptions}
                      value={month}
                      onChange={(e) => setMonth({ ...e })}
                    />
                  </div>

                  <div className="me-4">
                    <p>
                      Select Year <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <Select
                      styles={dcrStyles}
                      placeholder="Select Year"
                      className="mt-3"
                      options={yearOptions}
                      value={year}
                      onChange={(e) => setYear({ ...e })}
                    />
                  </div>
                  <div>
                    <p>
                      Filter Data
                    </p>
                    <Select
                      styles={dcrStyles}
                      placeholder="Select Filter Type"
                      className="mt-3"
                      options={filterOptions}
                      value={filter}
                      onChange={(e) => setFilter({ ...e })}
                    />
                  </div>
                </div>
                {loggedIn?.user?.des < 121 ||
                  loggedIn?.user?.designation?.priority > 1 ||
                  loggedIn?.user?.isAdmin ? (
                  <div>
                    <p className="util-select-user__label">Select User</p>
                    <div className="util-select-user">
                      <MdOutlineGroup className="util-select-user__icon" />
                      <Select
                        styles={selectUserStyles}
                        options={employeeSelect}
                        placeholder="Select User"
                        className="mt-3"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser({ ...e })}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </form>
            <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
              <h4>
                <span className="tp-indicator-9 me-2"></span>Approved
              </h4>
              <h4>
                <span className="tp-indicator-10 me-2"></span>Rejected
              </h4>
              <h4>
                <span className="tp-indicator-5 me-2"></span>Pending
              </h4>
              <h4>
                <span className="tp-indicator-12 me-2"></span>Not Submitted
              </h4>
            </div>
            <div className="filter-table">
              <Table defaultPageSize={50} columns={columns} data={tableData} empl={selectedUser ? selectedUser : loggedIn?.user} month = {month} fileSrc="Expense"/>
            </div>

            <div className="expense-calculation">
              <div className="expense-calculation__container">
                <div className="expense-calculation__container-icon">
                  <MdAttachMoney className="expense-calculation__container-icon-money expense-calculation__container-icon-money--pending" />
                </div>
                <div className="expense-calculation__money">
                  <h3>Total Pending Expense</h3>
                  <p>₹{totalExpense.totalExpensePending || 0}</p>
                </div>
              </div>
              <div className="expense-calculation__container">
                <div className="expense-calculation__container-icon">
                  <MdAttachMoney className="expense-calculation__container-icon-money expense-calculation__container-icon-money--approved" />
                </div>
                <div className="expense-calculation__money">
                  <h3>Total Approved Expense</h3>
                  <p>₹{totalExpense.totalExpenseApproved || 0}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imageShow ? <ImagePopup data={image} /> : null}
      {showRemark ? <RemarkPopup data={remarkData} type={popupType} /> : null}
      {showEdit && <EditPopup />};
    </main>
  );
};

export default Expenses;
