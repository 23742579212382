import { useEffect, useState, useMemo, useCallback } from "react";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { adminStyles, tpStyles } from "../../../public/stylesheets/selectStyles";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import {
  viewTargets,
  LinkDP,
  ViewLinkedDP,
  updateLinkedDP,
  deleteLinkedDP
} from "../../../../services/targets";
import Table from "../../../../components/Table";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { viewProducts } from "../../../../services/products";
import customToast from "../../../../components/CustomToast";
import { MdArrowBackIosNew, MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const DoctorProduct = () => {
  const [docs, setDocs] = useState([]);
  const [doctor, setDoctor] = useState();
  const [prod, setProd] = useState([]);
  const [products, setProducts] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [editProducts, setEditProducts] = useState([]);
  const [editDocProd, setEditDocProd] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [docProdList, setDocProdList] = useState([])
  const [percentage, setPercentage] = useState();
  const [isFixedAmount, setIsFixedAmount] = useState();
  const navigate = useNavigate();
  // console.log(isFixedAmount)

  useEffect(() => {
    viewTargets("doctor", { showApproved: true }).then((response) => {
      setDocs(response?.data);
    }).catch(e => console.log(e));

    viewProducts("").then((response) => {
      setProd(response?.data);
    });
  }, []);

  useEffect(() => {
    ViewLinkedDP().then((response) => {
      setDocProdList(response.data);
    });
  }, [isEdited, isCreated, isDeleted]);

  const EditPopup = ({ data }) => {
    const handleEdit = async (e) => {
      try {
        e.preventDefault();
        if (editProducts.length === 0)
          return customToast.error("Field cannot be empty");

        // console.log(editProducts)
        const updatedProducts = editProducts.map((el) => {
          // console.log(el.isFixed)
          return el.isFixed ?
            {
              productId: el._id,
              fixedAmount: el.fixedAmount,
              percentage: el.percentage,
              isFixed: el.isFixed
            } :
            {
              productId: el._id,
              percentage: el.percentage,
              fixedAmount: el.fixedAmount,
              isFixed: el.isFixed
            }
        } )

        // console.log(updatedProducts)

        let data = await updateLinkedDP({ products: updatedProducts, docProdId: editDocProd });
        console.log(data)
        customToast.success("Products updated successfully");
        setIsEdited(!isEdited);
        setShowEdit(false);
      } catch (err) {
        customToast.error("Sorry, Unable to update details");
      }
    };
    const handleDelete = async () => {
      try {
        await deleteLinkedDP({ docProdId: editDocProd });
        setShowEdit(false);
        customToast.success("Deleted successfully");
        setIsDeleted(!isDeleted);
      } catch (err) {
        customToast.error("Sorry, Unable to delete");
      }
    };
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="state">
                  Edit Products <span className="asterisk-imp">*</span>
                </label>
                <h4>Note : To edit the percentage, again add same product with updated percentage. </h4>
                <br />
                <Select
                  isMulti
                  name="products"
                  id="products"
                  value={editProducts}
                  options={productsSelect}
                  onChange={(e) => setEditProducts([...e])}
                  styles={{ ...adminStyles }}
                  placeholder="Select Products"
                  className="multi-select-dropdown w-100"
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="button"
                className="button-delete mt-4"
                onClick={handleDelete}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Doctor Name",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Products",
        accessor: "prodArr",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 140,
        maxWidth: 140,
        Cell: (props) => {
          // console.log(props?.row?.original);
          return props?.row?.original?.prodArr?.map((e) => (
            e.isFixed ?
              <div key={e._id}>
                {e.name} [{e.fixedAmount}]
              </div> :
              <div key={e._id}>
                {e.name} [{e.percentage}%]
              </div>
          ));
        },
      },
      // {
      //   Header: "Percentage",
      //   accessor: "percentage",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Actions",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
        Cell: (props) => (
          <span
            onClick={() => {
              setEditProducts(
                props?.row?.original?.prodArr?.map((e) => ({
                  ...e,
                  label: e.name,
                  value: e._id,
                }))
              );
              setEditDocProd(props?.row?.original._id);
              setShowEdit(true);
            }}
            className="react-table-view-link"
          >
            <RiEdit2Fill className="icon-color-green" />
          </span>
        ),
      },
    ],
    []
  );

  const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);

  const productsSelect = useMemo(() => generateSelectData(prod, "name"), [prod]);

  const SubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (!doctor || !products || percentage === "")
        return customToast.error("Please fill all the fields");

      // Construct the products array with the percentage for each product
      const productsWithPercentage = {
        productId: products._id,
        // percentage: percentage,
        isFixed: isFixedAmount
      };

      isFixedAmount ? productsWithPercentage.fixedAmount = percentage
        : productsWithPercentage.percentage = percentage
      // console.log(productsWithPercentage)

      let data = await LinkDP({ doctor: doctor._id, products: productsWithPercentage });
      if (data) {
        setIsCreated(!isCreated);
        setDoctor(null);
        setProducts(null);
        setPercentage('');
        return customToast.success("List successfully added");
      }
    } catch (err) {
      return customToast.error("An unexpected error occured");
    }
  };


  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading justify-content-between py-2">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              className="admin-creation-content__back-button"
              onClick={() => navigate("/crm")}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Doctor Products List
            </span>
          </h2>
          <div className="d-flex gap-4 align-items-center pt-4" >
            <div className="form__controll " style={{ width: "fit-content", color: "#c7c4e9" }}>
              <span className="">Fixed Amount</span>
              <label className="toggle-label2 mx-3" >
                <input
                  type="checkbox"
                  checked={isFixedAmount}
                  onChange={(e) => setIsFixedAmount(e.target.checked)}
                />
                <span />
              </label>
            </div>
          </div>
        </section>
        {/* <PageTitle title="Doctor Products List" isCRM={true} />
        <div className="d-flex justify-content-end gap-4 align-items-center pt-4" >
            <div className="form__controll " style={{ width: "fit-content", color: "#c7c4e9" }}>
              <span className="">Fixed Amount</span>
              <label className="toggle-label2 mx-3" >
                <input
                  type="checkbox"
                  checked={isFixedAmount}
                  onChange={(e) => setIsFixedAmount(e.target.checked)}
                />
                <span />
              </label>
            </div>
          </div> */}
        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="doctor">Select Doctor</label>
                <br />
                <Select
                  name="doctor"
                  id="doctor"
                  value={doctor}
                  options={doctorSelect}
                  onChange={(e) => setDoctor({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Doctor"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="DWPLproducts">Select Products</label>
                <br />
                <Select
                  name="products"
                  id="DWPLproducts"
                  value={products}
                  options={productsSelect}
                  onChange={(e) => setProducts({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Products"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="percentage">Percentage</label>
                <br />
                <input
                  type="number"
                  id="percentage"
                  name="percentage"
                  value={percentage}
                  onChange={(e) => setPercentage(e.target.value)}
                  placeholder="Enter Percentage"
                  className="percentage-input"
                />
              </div>
              {/* <div className="col-sm-12 col-md-4 col-lg-3">
              <div className="d-flex gap-4 align-items-baseline">
              <label htmlFor="fixedAmount">Fixed Amount</label>
                <input
                  type="checkbox"
                  id="fixedAmount"
                  checked={isFixedAmount}
                  onChange={(e) => setIsFixedAmount(e.target.checked)}
                  style={{width:"16px", height: "16px", cursor: "pointer"}}
                />
              </div>
              </div> */}
              <div className="col-sm-12 col-md-4 col-lg-3 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  ADD
                </button>
              </div>
            </div>
          </form>

          <div className="filter-table">
            {docProdList?.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({docProdList?.length}) Entries
              </h2>
            )}
            <Table columns={columns} data={docProdList} />
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default DoctorProduct;

//back 1-