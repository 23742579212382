import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../../components/PageTitle";
import { formatDate } from "../../../../../../utils/helper";

const CreationViewDCSDetails = () => {
  const location = useLocation();
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title={`Doctor Details`} hierarchy={true} />
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{location?.state?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Degree</h4>
          <p>{location?.state?.degree}</p>
        </div>

        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{location?.state?.workingArea}</p>
        </div>

        <div className="user-detail-box">
          <h4>Specialization</h4>
          <p>{location?.state?.specialization}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Address</h4>
          <p>{location?.state?.address}</p>
        </div>

        <div className="user-detail-box">
          <h4>Anniversary</h4>
          <p>{formatDate(location?.state?.anniversary)}</p>
        </div>

        <div className="user-detail-box">
          <h4>BirthDay</h4>
          <p>{formatDate(location?.state?.birthday)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Category</h4>
          <p>{location?.state?.category?.label}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Clinic Contact</h4>
          <p>{location?.state?.contact}</p>
        </div>

        <div className="user-detail-box">
          <h4>Doctor Code</h4>
          <p>{location?.state?.doctorCode}</p>
        </div>

        <div className="user-detail-box">
          <h4>E-mail</h4>
          <p>{location?.state?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Mobile</h4>
          <p>{location?.state?.mobile}</p>
        </div>
      </section>
    </div>
  );
};

export default CreationViewDCSDetails;
