import React, { useEffect, useMemo, useRef, useState } from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Lightbox from "yet-another-react-lightbox";
import Select from "react-select";
import {
  deleteProductImage,
  viewAllProductImages,
  viewDoctorWiseProductImages,
  viewSPLWiseProductImages,

} from "../../../services/eDetailing";
import { viewProducts } from "../../../services/products";
import { viewTargets } from "../../../services/targets";
import { viewSpecialization } from "../../../services/targets";
import {
  generateSelectData,
  generateSelectUser,
} from "../../../utils/serialiseResponse";
import { MdDeleteOutline, MdOutlineGroup } from "react-icons/md";
import { MdArrowDownward } from "react-icons/md";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { viewUsersForUtilities } from "../../../services/utilities";
import { selectUserStyles } from "../../public/stylesheets/selectStyles";
import customToast from "../../../components/CustomToast";


const EDetailing = () => {
  const loggedIn = useSelector(({ user }) => user);
  const [prod, setProd] = useState([]);
  const [docs, setDocs] = useState([]);
  const [open, setOpen] = useState(false);
  const [index,setIndex] = useState(0);
  
  const [specializations , setSepecializations] = useState([]);
  const [doctor, setDoctor] = useState(null);
  const [splzation , setSplzation] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [type, setType] = useState(null);
  const [page, setPage] = useState(1);
  const [showEdit, setShowEdit] = useState(false);
  const [itemDelete, setItemDelete] = useState({});
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [paginateToggle,setPaginateToggle] = useState(true);
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    viewTargets("doctor", { dcr: true , showApproved:true}).then((response) => {
      setDocs(response?.data);
    });

    viewProducts("").then((response) => {
      setProd(response?.data);
    });
    viewSpecialization().then((response) => {
      setSepecializations(response?.data);
    });
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  

  const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);
  const splzationSelect = useMemo(() => generateSelectData(specializations, "name"), [specializations]);
  
  

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#2B2C47",
      border: "2px solid #36ABF9",
      borderRadius: "5px",
      color: "#9e9ad0",
      fontSize: "1.5rem",
      fontWeight: "500",

      width: "20rem",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#2B2C47",
      color: "#9E9AD1",
      fontFamily: "inherit",
      fontSize: "1.5rem",

      "&:hover": {
        backgroundColor: "#393b5f",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#9e9ad0",
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#2B2C47",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#C7C4E9",

      "&:hover": {
        color: "#9E9AD1",
      },
    }),
  };

  const typeOptions = [
    { value: 1, label: "All Images" },
    { value: 2, label: "Doctor wise product Images" },
    { value: 3, label:"Specialization Wise Product List"},
  ];

  let submitHandler = async (e) => {
    e.preventDefault();
    if (!type) return customToast.error("Please select a type");
    if (type?.value === 1) {
      viewAllProductImages({ page:1 }).then((data) => {
        setProductImages([...data?.data]);
        
      });
    } else if (type?.value === 2) {
      if (!doctor) return customToast.error("Please select a doctor");
      try {
        let data = await viewDoctorWiseProductImages({
          docId: doctor._id,
          userId: selectedUser?.value,
        });
        setProductImages(data?.data);
      } catch (err) {
        setProductImages([]);
      }
    }else if (type?.value === 3) {
      if (!splzation) return customToast.error("Please select a specialization");
      try {
        let data = await viewSPLWiseProductImages({
          splId: splzation._id,
          userId: selectedUser?.value,
        });
        setProductImages(data?.data);
      } catch (err) {
        setProductImages([]);
      }
    }
  };
  let handleDelete = async ({ prodId, link, ID }) => {
    await deleteProductImage(prodId, { link });

    setProductImages(productImages.filter((e) => e.ID !== ID));
    setShowEdit(false);
  };

  let loadImages = async () => {
    let counter = page;
    counter++;
    setPage(counter);
    let data = await viewAllProductImages({ page: counter });
    if(data.data.length === 0) setPaginateToggle(false)
    setProductImages([...productImages, ...data.data]);
  };

  const EditPopup = () => {
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Are you sure you want to delete?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                onClick={() => {
                  handleDelete(itemDelete);
                }}
                className="button-delete mt-4"
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                onClick={() => setShowEdit(false)}
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  console.log(productImages);
  return (
    <main className="main-content ">
      <div className="expense">
        <div className="row">
          <h3 className="web-app__heading">E-Detailing</h3>

          <form onSubmit={submitHandler}>
            <div className="util-tp-query d-flex justify-content-between">
              <div className="d-flex">
                <div className="util-tp-filter me-5">
                  <p className="mb-2">
                    Select Type <span className="asterisk-imp">*</span>
                  </p>

                  <Select
                    styles={selectStyles} 
                    placeholder="Select Type"
                    value={type}
                    options={typeOptions}
                    onChange={(e) => {
                      setType({ ...e });
                      setProductImages([]);
                      setPage(1);
                    }}
                  />
                </div>
                {type?.value === 2 ? (
                  <div className="util-tp-filter ms-5">
                    <p className="mb-2">
                      Select Doctor <span className="asterisk-imp">*</span>
                    </p>

                    <Select
                      styles={selectStyles}
                      placeholder="Select Doctor"
                      value={doctor}
                      options={doctorSelect}
                      onChange={(e) => {
                        setDoctor({ ...e });
                      }}
                    />
                  </div>
                ) : null}
                {type?.value === 3 && (
                  <div className="util-tp-filter ms-5">
                    <p className="mb-2">
                      Select Specialization <span className="asterisk-imp">*</span>
                    </p>

                    <Select
                      styles={selectStyles}
                      placeholder="Specialization"
                      value={splzation}
                      options={splzationSelect}
                      onChange={(e) => {
                        setSplzation({ ...e });
                      }}
                    />
                  </div>
                )}
                {loggedIn?.user?.des < 121 ||
                loggedIn?.user?.designation?.priority > 1 ||
                loggedIn?.user?.isAdmin ? (
                  type?.value === 2 ? (
                    <div className="util-tp-filter ms-5">
                      <p className="util-select-user__label mb-2">
                        Select User
                      </p>
                      <div className="util-select-user">
                        <MdOutlineGroup className="util-select-user__icon" />
                        <Select
                          styles={selectUserStyles}
                          options={employeeSelect}
                          placeholder="Select User"
                          value={selectedUser}
                          onChange={(e) => setSelectedUser({ ...e })}
                        />
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
              <div className="util-tp-filter mt-4">
                <button type="submit" className="button-blue-gradient mt-3">
                  Submit
                </button>
              </div>
            </div>
          </form>
          
          <div className="e-detailing-parent">
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 300: 2, 900: 3 }}
                className="e-detailing-cards-container"
              >
                <Masonry>
                  {productImages.length > 0 ? (
                    productImages.map((e, index) => (
                    <div className="edetailing-img-card"
                      key={index}
                      onClick={(e)=>{ setIndex(index);
                        setOpen(true);
                        }}
                    >
                      <img src={e.uri} alt="" className="edetailing-img" />
                      <div className="img-dets-wrapper d-flex justify-content-between align-items-center ps-3 pe-3 ">
                        <h5 className="web-app__heading fs-2 text-white  bg-dark">{e.prodName}</h5>

                        {loggedIn?.user?.isAdmin ? (
                          <MdDeleteOutline
                            onClick={(event) => {
                              event.stopPropagation();
                              setItemDelete({
                                prodId: e._id,
                                link: e.uri,
                                ID: e.ID,
                              });

                            setShowEdit(true);
                            setShowEdit(true);
                            // handleDelete({ prodId: e._id, link: e.img,ID:e.ID })
                              setShowEdit(true);
                            // handleDelete({ prodId: e._id, link: e.img,ID:e.ID })
                            }}
                            style={{
                              cursor: "pointer",
                              marginTop: "-1rem",
                              fontSize: "1.4vmax",
                            }}
                            className="button-delete__icon bg-dark"
                          />
                        ) : null}
                      </div> 
                    </div>
                  ))): (
                    <label className="web-app__heading">No Images here </label>
                  )
                  }
                </Masonry>
              </ResponsiveMasonry>
            {productImages.length > 0 ? (
              type?.value === 1 ? (
                paginateToggle?<div className="view-more d-flex mt-5" onClick={loadImages}>
                <h5 className="web-app__heading ms-auto d-flex align-items-center">
                  View More
                  <MdArrowDownward
                    className="me-5"
                    style={{ fontSize: "2.4rem" }}
                  />
                </h5>
              </div>:null
              ) : null
            ) : null}
          </div>
        </div>
        {showEdit ? <EditPopup /> : null}
        <Lightbox
          index={index}
          open={open} 
          close={() => setOpen(false)}
          slides={productImages.map(e=>({src:e.uri}))}
        />
      </div>
    </main>
  );
};

export default EDetailing;
