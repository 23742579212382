import React,{ useState, useMemo, useEffect, useCallback, useRef } from "react";
import Table from "../../../../../components/Table";
import {FaCalendar, FaCartPlus,} from "react-icons/fa" ;
import {ImLocation} from 'react-icons/im';
import {MdDownload} from "react-icons/md" ;
import serialise from "../../../../../utils/serialiseResponse";
import { useLocation} from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { returnMonthFromIndex } from "../../../../../utils/helper";
import { formatDate } from "../../../../../utils/helper";
const AdminPSApprovalDetail = () => {
  
  const location = useLocation();
 
  const [finalArray, setFinalArray] = useState([]);//this is the array that will get submitted
  
  useEffect(() => {
    const data = serialise(location?.state?.products);
    setFinalArray(data);
  }, [location?.state]);
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 50,
      //   maxWidth: 50,
      // },
      {
        Header: "Product Name",
        accessor: "product.name",
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        wordWrap:"break-word",
        showTotal:true,
        placeholderTotal:"Total",
        Cell : (props) => {
          return <span>{props?.row?.original?.product.name ? props?.row?.original?.product.name : props?.row?.original?.name}</span>
          }
      },
      {
        Header:"Packaging",
        accessor: "product.packaging",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 65,
        maxWidth: 65,
        wordWrap:"break-word",
        Cell : (props) => {
          return <span>{props?.row?.original?.product.packaging ? props?.row?.original?.product.packaging : props?.row?.original?.packaging}</span>
          }
      },
      {
        Header:"Unit Price",
        accessor:"price",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 65,
        maxWidth: 65,
      },
      {
        Header:"Price Type",
        accessor:"priceType",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 65,
        maxWidth: 65,
        Cell:(props) =>{
          let text = props?.row?.original?.priceType;
          return text.toUpperCase(); 
        }
      },
      {
        Header:"Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        Header: "Sales Value",
        accessor: "finalPrice",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal:true,
        totalAccessor:"finalPrice",
      },
    ],
    []
  );

  useEffect(() => {
    console.log(finalArray, location?.state)
  }, [finalArray])
  
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Primary Sales Details" hierarchy={true}/>
        <form >
            <div className="util-inner-info-box-container p-3">
                <div className="util-inner-info-box">
                <FaCalendar className="fs-2 text-light"/>
                <h3 className="d-inline ms-3">{formatDate(location?.state?.date)}</h3>
                </div>
                <div className="util-inner-info-box">
                <ImLocation className="fs-2 text-light" />
                <h3 className="d-inline ms-3">
                    {location?.state?.headquarter}
                </h3>
                </div>
                <div className="util-inner-info-box">
                <FaCartPlus className="fs-2 text-light"/>
                <h3 className="d-inline ms-3">
                    {location?.state?.stockist}
                </h3>
                </div>
                <div className="util-inner-info-box">
                <MdDownload className="fs-1 text-light"/>
                <h3 className="d-inline ms-3">Download</h3>
                </div>
            </div>
            <div className="filter-table" style={{minHeight:"150px" , overflow:"auto"}}>
                <Table columns={columns} data={finalArray} />
            </div>
        </form>
      </div>
    </main>
  );
};

export default AdminPSApprovalDetail;
