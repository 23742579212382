import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import react,{ useMemo } from "react";
import serialise from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { FaEye } from "react-icons/fa";
import { formatDate } from "../../../../../../utils/helper";

const DatePS = ({reports}) => {
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   disableFilters:true,
      //   disableSortBy:true,
      //   minWidth:50,
      //   maxWidth:50
      // },
      {
        Header: "Date",
        accessor:"date",
        minWidth:80 ,
        maxWidth:80 ,
        disableFilters:true,
        disableSortBy:true,
        showTotal:true,
        placeholderTotal:"Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth:50,
        maxWidth:50,
        Cell: (props) => {
          const dateRange = {
            startDate: props?.row?.original?.date,
            endDate: props?.row?.original?.date,
          };
          return (
            <Link
            to={"./stk"}
            state={{ data: props?.row?.original?._id, dateRange:dateRange }}
            className="util-table-view-details-link"
          >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    []
  );
  
  const tableData = useMemo(() => serialise(reports), [reports]);
  
  return (
    <div className="filter-table">
        {reports.length > 0 && (
            <h2 className="web-app__heading mb-4 ps-3">
                Showing ({reports.length}) Entries
            </h2>
        )}
        {tableData.length > 0 ? (
            <Table columns={columns} data={tableData} fileSrc="Primary Sales Report (Date)" />
        ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
        )}
    </div> 
  );
};

export default DatePS ;